import React, { useEffect, useRef } from 'react'

function TranslateComponent() {
  const googleTranslateRef = useRef(null)

  useEffect(() => {
    let intervalId = null
    const checkGoogleTranslate = () => {
      if (
        window.google &&
        window.google.translate &&
        window.google.translate.TranslateElement.InlineLayout
      ) {
        if (document.getElementById('accountHeader')) {
          const w = document.getElementById('accountHeader').offsetWidth
          googleTranslateRef.current.style.right = `${w + 50}px`
          clearInterval(intervalId)
          window.google.translate.TranslateElement(
            {
              pageLanguage: 'zh-hant',
              autoDisplay: false,
              includedLanguages: 'en',
              layout:
                window.google.translate.TranslateElement.InlineLayout.SIMPLE,
            },
            googleTranslateRef.current
          )
        }
      }
    }
    intervalId = setInterval(checkGoogleTranslate, 100)
    return () => clearInterval(intervalId)
  }, [])

  return (
    <div id="google-translate" ref={googleTranslateRef}>
      {}
    </div>
  )
}

export default TranslateComponent
